<template>
  <div class="rightLink">
    <div class="bottomDiv" style="height: 100%">
      <div class="title">电动汽车模板管理</div>
      <div class="formDiv2" style="margin-left: 15vw; margin-top: 20px">
        <el-form label-position="right" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="4"></el-col>
            <el-col :span="8">
              <el-form-item label="模板名称:">
                <el-input
                  v-model="FormData2.price_name"
                  placeholder="请输入模板名称"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
                <el-form-item label="车型:">
                  <el-select v-model="FormData.type_id" placeholder="请选择充电类型">
                    <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
                  </el-select>
                </el-form-item>
              </el-col> -->

            <el-col :span="1">
              <div
                class="BtnSearch"
                style="margin-top: 5px"
                @click="searchBtn1()"
              >
                查询
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->

      <div class="tableDiv1">
        <el-table
          ref="multipleTableRef"
          :data="tableData2"
          border
          v-loading="loading"
          height="530px"
          style="
            width: 90%;
            margin: 30px auto;

            overflow-y: auto;
          "
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="模板id" prop="id" width="70" />
          <el-table-column label="模板名称" prop="price_name" width="120" />

          <el-table-column
            label="谷峰电费(元)"
            prop="d_electric"
            :formatter="rounding"
          />
          <el-table-column
            label="谷峰服务费(元)"
            prop="d_service"
            :formatter="rounding"
          />
          <el-table-column
            label="平峰电费(元)"
            prop="p_electric"
            :formatter="rounding"
          />
          <el-table-column
            label="平峰服务费(元)"
            prop="p_service"
            :formatter="rounding"
          />
          <el-table-column
            label="尖峰电费(元)"
            prop="j_electric"
            :formatter="rounding"
          />
          <el-table-column
            label="尖峰服务费(元)"
            prop="j_service"
            :formatter="rounding"
          />
          <el-table-column
            label="高峰电费(元)"
            prop="g_electric"
            :formatter="rounding"
          />
          <el-table-column
            label="高峰服务费(元)"
            prop="g_service"
            :formatter="rounding"
          />

          <!-- <el-table-column label="充电车型" prop="type">
              <template #default="scope">
                <span v-if="scope.row.type_id === 2">电动车</span>
                <span v-if="scope.row.type_id === 4">电动汽车</span>
              </template>
            </el-table-column> -->
          <el-table-column label="操作" width="180" align="center">
            <template #default="scope">
              <!-- <div
                  class="BtnSearch1 BtnSearch"
                  @click="detail(scope.$index, scope.row)"
                >
                  详情
                </div> -->
              <!-- <el-button round type="success" plain size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
              <el-button
                round
                color="#42a7a9"
                plain
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                round
                type="warning"
                plain
                size="mini"
                @click="del(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            :current-page="FormData2.page"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total1"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
  <div v-dialogdrag>
    <el-dialog
      v-model="Dialog.editLevy"
      :title="Dialog.title"
      width="820px"
      :before-close="closeDialog"
      class="dialog"
    >
      <div class="formDiv">
        <el-form
          ref="FormRules"
          :rules="rules"
          :model="DialogData"
          label-position="right"
          label-width="100px"
        >
          <el-row :gutter="20">
            <el-col :span="9">
              <el-form-item label="模板ID:">
                <el-input v-model="Dialog.DialogData.id" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="模板名称:">
                <el-input
                  v-model="Dialog.DialogData.price_name"
                  placeholder="请输入模板名称"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="type2_show">
            <el-col :span="9">
              <el-form-item label="单价:">
                <el-input
                  v-model="Dialog.DialogData.price"
                  placeholder="请输入单价"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="计费类型:">
                <el-select
                  style="width: 100%"
                  v-model="Dialog.DialogData.type"
                  :disabled="isEdit"
                >
                  <el-option value="0" label="小时" />
                  <el-option value="1" label="度" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 四轮 -->
          <el-row :gutter="20" v-show="type4_show">
            <el-col :span="9">
              <el-form-item label="谷峰电费:">
                <el-input
                  v-model="Dialog.DialogData.d_electric"
                  placeholder="请输入谷峰电费"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="谷峰服务费:">
                <el-input
                  v-model="Dialog.DialogData.d_service"
                  placeholder="请输入谷峰服务费"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="type4_show">
            <el-col :span="9">
              <el-form-item label="平峰电费:">
                <el-input
                  v-model="Dialog.DialogData.p_electric"
                  placeholder="请输入平峰电费"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="平峰服务费:">
                <el-input
                  v-model="Dialog.DialogData.p_service"
                  placeholder="请输入平峰服务费"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="type4_show">
            <el-col :span="9">
              <el-form-item label="尖峰电费:">
                <el-input
                  v-model="Dialog.DialogData.j_electric"
                  placeholder="请输入尖峰电费"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="尖峰服务费:">
                <el-input
                  v-model="Dialog.DialogData.j_service"
                  placeholder="请输入尖峰服务费"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="type4_show">
            <el-col :span="9">
              <el-form-item label="高峰电费:">
                <el-input
                  v-model="Dialog.DialogData.g_electric"
                  placeholder="请输入高峰电费"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="高峰服务费:">
                <el-input
                  v-model="Dialog.DialogData.g_service"
                  placeholder="请输入高峰服务费"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer" v-show="!isEdit">
          <el-button class="Btn" @click="closeDialog">取消</el-button>
          <el-button class="Btn" type="primary" @click="submitDialog"
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_template, upd_template, delete_template } from "@/request/api";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  name: "EditArea",
  setup() {
    const data = reactive({
      //表单数据
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",
      typeList: [
        {
          value: 0,
          label: "小时",
        },
        {
          value: 1,
          label: "度",
        },
      ],
      type: "",
      FormData2: {
        username: localStorage.getItem("username"),
        type_id: "4",
        page: 1,
      },
      tableData1: [],
      tableData2: [],
      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
        title: "",
      },
      loading: false,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      total1: 400,
      checked: 0,
      small: true,
      isEdit: false,
      type2_show: false,
      type4_show: false,
    });
    const searchBtn1 = () => {
      data.FormData2.page = 1;
      getList1();
    };

    const getList1 = () => {
      const dataa = data.FormData2;
      data.loading = true;
      sel_template(dataa).then((res) => {
        if (res) {
          console.log(res, "模板列表");
          data.loading = false;
          if (res.code == 200) {
            data.tableData2 = res.data;
            data.total1 = res.count_data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };

    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const handleChange = (value) => {};
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.FormData2.page = val;
      getList1();
    };
    const handleEdit = (index, row) => {
      if (row.type_id == 2) {
        (data.type2_show = true), (data.type4_show = false);
      } else if (row.type_id == 4) {
        (data.type2_show = false), (data.type4_show = true);
      }
      data.Dialog.editLevy = true;
      data.Dialog.title = "编辑";
      data.Dialog.DialogData = JSON.parse(JSON.stringify(row));
      data.isEdit = false;
    };
    const closeDialog = () => {
      data.Dialog.editLevy = false;
      data.Dialog.DialogData = {};
    };
    const detail = (index, row) => {
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
      data.isEdit = true;
      data.Dialog.title = "详情";
    };
    const submitDialog = () => {
      const dataa = data.Dialog.DialogData;
      upd_template(dataa).then((res) => {
        if (res) {
          closeDialog();
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: "编辑成功",
              type: "success",
            });
            getList1();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("编辑失败");
        }
      });
    };
    const del = (index, row) => {
      ElMessageBox.confirm("您确认删除此模板吗？", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const dataa = {
          id: row.id,
        };
        delete_template(dataa)
          .then((res) => {
            if (res.code == 200) {
              ElMessage.success(res.msg);
              getList1();
            } else {
              ElMessage.error("删除失败");
            }
          })
          .catch(() => {
            ElMessage.error("操作失败");
          });
      });
    };

    onMounted(() => {
      getList1();
    });
    return {
      ...toRefs(data),
      searchBtn1,
      getList1,
      handleChange,
      handleSizeChange,
      handleCurrentChange,
      handleEdit,
      del,
      detail,
      closeDialog,
      submitDialog,
      rounding,
    };
  },
};
</script>
<style scoped>
.formDiv {
  margin-top: 20px;
}
.el-row {
  height: 45px;
}
.el-select /deep/ .el-input__wrapper {
  width: 288px !important;
  --el-input-focus-border-color: #7bd8d3;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  margin-left: -100px;
}
.Btns {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 175px;
  margin-left: 40px;
  margin-top: 20px;
}
.btn1 {
  margin-left: 340px;
}
.btn:hover {
  background-color: #05d5c7;
  border-color: #05d5c7;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch {
  float: left;
  width: 70px;
  margin: 0 4px;
  margin-top: 8px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.flex {
  display: flex;
}
.s1 {
  width: 35% !important;
}
.s2 {
  width: 65% !important;
}
</style>
